import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";

const Privacy = () => {
  const navigate = useNavigate();
  return (
    <div className="relative overflow-x-hidden min-h-[100vh] sm:pt-[200px] pt-[300px]">
      <div>
        <div
          className={`
         bg-[#102954]
        fixed top-0 w-[100%] z-[100000000]`}
        >
          <Header />
          <p className="text-center text-[#fff] font-[600] sm:text-[30px] text-[25px] my-[30px]">
            Privacy Policy
          </p>
        </div>
        <div className="bg-[#f4f9ff] p-[10%]">
          <p className="text-center hidden">
            Please read these terms carefully as you are bound by them By
            downloading our app and signing up, you accept our terms and
            conditions of service.{" "}
          </p>
          <p className="text-center m-auto pt-3 text-[16px]">
            Document effective 1st of June, 2020{" "}
          </p>
        </div>
        <div className="mt-5 mb-5 px-[8%] py-[2%]">
          <p className="sm:text-[24px] text-[20px] font-[600] text-[#102954] leading-[2px] mb-[40px]">
            1. Introduction
          </p>
          <p className="sm:text-[18px] text-[14px] mb-[60px]">
            Hi! We've created this privacy policy to explain how we collect,
            use, disclose and protect your information; including any nonpublic
            personal information.
          </p>
          <p className="sm:text-[18px] text-[14px] mb-[60px]">
            This policy applies to information we collect when you use our
            website(s) and mobile application(s) (collectively, "Services") or
            otherwise interact with us as described below. We may change this
            privacy policy from time to time. If we make changes, we will notify
            you by revising the date at the top of this policy, and in some
            cases, we may provide you with additional notice (such as by adding
            a statement to the homepages of our website or mobile application or
            by sending you an email notification).
          </p>
          <p className="sm:text-[18px] text-[14px] mb-[60px]">
            We encourage you to review the privacy policy whenever you interact
            with us to stay informed about our information practices and the
            ways you can help protect your privacy. This privacy policy applies
            to the Services provided by SpottR (“we” or “us”).{" "}
          </p>
          <p className="sm:text-[18px] text-[14px] font-[600] text-[#102954] mb-[30px]">
            1.1 Purpose
          </p>
          <p className="mb-[60px] sm:text-[18px] text-[14px]">
            The SpottR app helps users to find economic opportunities globally
            by joining the main ecosystem. Opportunities include, but is not
            limited to, goods & services for sale, jobs, business opportunities,
            events, information and other wealth creating opportunities.
          </p>
          <p className="sm:text-[24px] text-[20px] font-[600] text-[#102954] leading-[2px] mb-[40px]">
            2. Collection of Information
          </p>

          <p className="sm:text-[18px] text-[14px] font-[600] text-[#102954] mb-[30px]">
            2.1 Categories of Non-public Personal Information
          </p>
          <p className="mb-[60px] sm:text-[18px] text-[14px]">
            We collect nonpublic personal information you provide to us when you
            apply for a SpottR account or when you communicate with us about
            your SpottR account. The types of information we may collect include
            your name, address, phone number, email address, date of birth,
            national and/or bank account information, username, password, and
            next of kin details.{" "}
          </p>
          <p className="sm:text-[18px] text-[14px] font-[600] text-[#102954] mb-[30px]">
            2.2 Information About Your Transactions with Us{" "}
          </p>
          <p className="mb-[60px] sm:text-[18px] text-[14px]">
            As you use your SpottR account to spot opportunities and/or fulfill
            transactions, we collect nonpublic personal information in
            connection with each query , including time, transaction name,
            amount and merchant details. We may also collect photos, memos or
            other information that you attach to your transactions.
          </p>
          <p className="sm:text-[18px] text-[14px] font-[600] text-[#102954] mb-[30px]">
            2.3 Other Information we collect{" "}
          </p>
          <p className="mb-[60px] sm:text-[18px] text-[14px]">
            We may collect information you provide to us, such as your name,
            email address and any other information you choose to provide. For
            example, we may collect such information if you request an invite to
            join SpottR or if you enter into any contest or promotion.
          </p>
          <p className="sm:text-[18px] text-[14px] font-[600] text-[#102954] mb-[30px]">
            2.4 Information We Collect Automatically from our Services{" "}
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            When you access or use our Services, we automatically collect
            information about you as follows:
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            Log Information: We log information about your use of our Services,
            including the type of browser you use, access times, pages viewed,
            your IP address and the page you visited before navigating to our
            Services.
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            Device Information: We collect information about the computer or
            mobile device you use to access our Services, including the hardware
            model, operating system and version, unique device identifiers and
            mobile network information.
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            Location Information: We may collect your location information from
            your mobile device with your prior consent. Also, we have
            incorporated Google Maps into our Services, and you may consent to
            the collection of location information by Google Maps in connection
            with your use of this maps service. Any information collected via
            your use of Google Maps will be transmitted directly to Google and
            is not collected by us. Please refer to Google’s privacy policy for
            details about their collection, use and sharing of this information.
            For information about how to disable the collection of location
            information from your device, please see “Your Choices” below.
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            Information Collected by Cookies and Other Tracking Technologies :
            We use various technologies to collect information on our Services
            and other websites, and this may include sending cookies to your
            computer or mobile device. Cookies are small data files stored on
            your hard drive or in device memory that help us to improve our
            Services and your experience, see which areas and features of our
            Services are popular and count visits. For more information about
            cookies, and how to disable them, please see “Your Choices” below.
            We may also collect information using web beacons (also known as
            "tracking pixels"). Web beacons are electronic images that may be
            used in our Services or emails and help deliver cookies, count
            visits, understand usage and campaign effectiveness and determine
            whether an email has been opened and acted upon.{" "}
          </p>
          <p className="mb-[60px] sm:text-[18px] text-[14px]">
            How We Respond to Do Not Track Signals : We allow Do Not Track
            browser configurations. We use cookies and other tracking
            technologies, and Do Not Track status may alter the available
            services with certain browsers when these tracking functionalities
            are used to provide services. We do not alter the information our
            applications attempt to collect based on your Do Not Track
            configuration. How We Respond to Do Not Track Signals{" "}
          </p>
          {/* <p className="terms-body blue">partner@theSpottrapp.com</p> */}
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            Google Play Services
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">AdMob</p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            Firebase Analytics
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">Crashlytics</p>
          <p className="mb-[60px] sm:text-[18px] text-[14px]">
            Adjust Facebook
          </p>
          <p className="sm:sm:text-[24px] text-[20px] font-[600] text-[#102954] leading-[2px] mb-[40px]">
            4. Use of Information
          </p>
          <p className="mb-[30px] sm:text-[18px] text-[14px]">
            We may use your information, including non-public personal
            information as follows:
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            Provide, maintain and improve our Services
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            Provide and deliver the products and services you request, process
            transactions and send you related information, including
            confirmations.{" "}
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            Verify your identity and prevent fraud.{" "}
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            Send you technical notices, updates, security alerts and support and
            administrative messages communicate with you about products,
            services, offers, promotions, rewards, and events offered by SpottR
            and others, and provide news and information we think will be of
            interest to you. monitor and analyze trends, usage and activities in
            connection with our Services.{" "}
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            Personalize and improve the Services and provide advertisements,
            content or features that match user profiles or interests.{" "}
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            Process and deliver contest or promotion entries and rewards. link
            or combine with information we get from others to help understand
            your needs and provide you with better service.{" "}
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            Carry out any other purpose for which the information was collected.
          </p>
          <p className="mb-[60px] sm:text-[18px] text-[14px]">
            SpottR is based in Nigeria and the information we collect is
            governed by the Nigerian law. By accessing or using the Services or
            otherwise providing information to us, you consent to the processing
            and transfer of information in and to Nigeria and other countries.{" "}
          </p>
          <p className="sm:sm:text-[24px] text-[20px] font-[600] text-[#102954] leading-[2px] mb-[40px]">
            4. Disclosure of Information
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            We may disclose any information we collect about current and former
            customers, including nonpublic personal information, to affiliates
            and non-affiliated third parties as follows{" "}
          </p>

          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            With financial service providers, including the financial
            institutions identified in your cardholder bank agreement that
            provide banking services in connection with your SpottR account.
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            With another user, when you sign up for SpottR's services via a
            referral link. The user that referred you may receive information
            indicating that you have enrolled with SpottR. You may avoid this
            sharing by not using a referral link to enroll.{" "}
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            With non-financial companies, such as email service providers that
            perform marketing services on our behalf, and fraud prevention
            service providers that use the information to provide services to
            SpottR and other companies.
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            With a non-affiliated third-party to access and transmit your
            personal and financial information from a relevant financial
            institution. You grant the third-party the right, power, and
            authority to access and transmit this information. according to
            terms of their privacy policy.
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            With other nonaffiliated companies for our everyday business
            purposes, such as to process transactions, maintain accounts,
            respond to court orders and legal investigations or report to credit
            bureaus. For example, in connection with our everyday business
            purposes, we may share information about you as follows:
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            In response to a request for information, if we are required by, or
            we believe disclosure is in accordance with, any applicable law,
            regulation or legal process.{" "}
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            With relevant law enforcement officials or other third parties, such
            as investigators or auditors, if we believe it is appropriate to
            investigate fraud.
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            In connection with, or during negotiations of, any merger, sale of
            SpottR's assets, financing or acquisition of all or a portion of our
            business to another company; and{" "}
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            With your consent or at your direction, including if we notify you
            that the information you provide will be shared in a particular
            manner and you provide such information.{" "}
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            We may also share aggregated or de-identified Information, which
            cannot reasonably be used to identify you. For example, we may share
            transaction zip codes with third parties to improve our accuracy in
            geo-locating opportunities and to improve the clarity of transaction
            descriptions.{" "}
          </p>
          <p className="mb-[60px] sm:text-[18px] text-[14px]">
            We will usually not share your personal information with other third
            parties without your consent. Where we need to transfer your data to
            another country, we shall ensure that such country to which the data
            is being transferred shall have adequate data protection law. We
            will seek your consent where we need to send your data to a country
            without an adequate data protection law.{" "}
          </p>

          <p className="sm:sm:text-[24px] text-[20px] font-[600] text-[#102954] leading-[2px] mb-[40px]">
            5. Your Choices{" "}
          </p>

          <p className="sm:text-[18px] text-[14px] font-[600] text-[#102954] mb-[30px]">
            5.1 Account Information{" "}
          </p>

          <p className="mb-[60px] sm:text-[18px] text-[14px]">
            You may edit your account profile information by logging into your
            account via our Services. If you wish to delete or deactivate your
            online account, please contact us via hello@theSpottRapp.com, but
            note that we may retain certain information as required by law or
            for legitimate business purposes.{" "}
          </p>

          <p className="sm:text-[18px] text-[14px] font-[600] text-[#102954] mb-[30px]">
            5.2 Promotional Communications{" "}
          </p>

          <p className="mb-[60px] sm:text-[18px] text-[14px]">
            You may opt out of receiving promotional emails and text messages
            from SpottR by following the instructions in those messages. Please
            note that if you opt out, we may still send you transactional or
            relationship messages, such as those about your account or our
            ongoing business relations.{" "}
          </p>

          <p className="sm:text-[18px] text-[14px] font-[600] text-[#102954] mb-[30px]">
            5.3 Location Information{" "}
          </p>

          <p className="mb-[60px] sm:text-[18px] text-[14px]">
            If you initially consent to the collection of location information
            by SpottR or Google Maps within our mobile application, you may be
            able to subsequently stop this collection through your device
            operating system settings or through your online account settings.
            If either of these opt-out options are not available to you, you may
            also disable location information by following the standard
            uninstall process to remove our mobile application from your device.{" "}
          </p>

          <p className="sm:text-[18px] text-[14px] font-[600] text-[#102954] mb-[30px]">
            5.4 Cookies{" "}
          </p>

          <p className="mb-[60px] sm:text-[18px] text-[14px]">
            Most web browsers are set to accept cookies by default. If you
            prefer, you can usually choose to set your browser to remove or
            reject browser cookies. Please note that if you choose to remove or
            reject cookies, this could affect the availability and functionality
            of our Services.{" "}
          </p>
          <p className="sm:text-[18px] text-[14px] font-[600] text-[#102954] mb-[30px]">
            5.5 Social Sharing Features{" "}
          </p>

          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            Our Services include social sharing features for common social
            networks to let users share content. These features are served from
            third-party services that may serve their own cookies. The use of
            such features enables the sharing of information with your friends
            or the public, depending on the settings you establish with the
            entity that provides the social sharing feature. For more
            information about the purpose and scope of data collection and
            processing in connection with social sharing features, please visit
            the privacy policies of the entities that provide these features.
          </p>

          <p className="sm:text-[18px] text-[14px] font-[600] text-[#102954] mb-[30px]">
            5.6 Analytics and Advertising Services Provided by Others{" "}
          </p>

          <p className="mb-[60px] sm:text-[18px] text-[14px]">
            We may allow others to provide analytics services on our behalf and
            to serve advertisements on our behalf across the Internet. These
            entities may use cookies, web beacons and other technologies to
            collect information about your use of the Services and other
            websites, including your IP address, web browser, pages viewed, time
            spent on pages, links clicked and conversion information. This
            information may be used by SpottR and others, to, among other
            things, analyze and track data, determine the popularity of certain
            content, deliver advertising targeted to your interests and better
            understand your online activity.{" "}
          </p>
          <p className="sm:sm:text-[24px] text-[20px] font-[600] text-[#102954] leading-[2px] mb-[40px]">
            6. Your Rights
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            The law gives you certain rights in respect of the information we
            hold about you. Below is a highlight of some of those rights. It is
            not a complete, exhaustive statement of your rights in respect of
            your personal data:
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            You have a right to consent to this Privacy Policy and to withdraw
            your consent at any time.
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            You have a right to a copy of the personal data we hold about you,
            as well as the information about what we do with it, who we share it
            with and how long we hold it for. We may make a reasonable charge
            for additional copies of that data in the case of unfounded and
            excessive requests.
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            You have a right to freely transfer your data received from us to
            any other person. you have the right to have the information we hold
            about you corrected, if it is factually inaccurate. You may contact
            us for this purpose.
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            In some circumstances, you have the right to the deletion of the
            information that we hold about you. you have a right to lodge a
            complaint about the handling of your personal information with the
            National Information Technology Development Agency (NITDA).
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            You have the right to ask us not to process your personal data for
            marketing purposes. We will usually inform you (before collecting
            your data) if we intend to use your data for such purposes or if we
            intend to disclose your information to any third party for such
            purposes.
          </p>
          <p className="mb-[60px] sm:text-[18px] text-[14px]">
            NITDA’s website has a wealth of useful information in respect of
            your rights over your personal data. If you wish to exercise your
            rights, you may write to us at hello@theSpottrapp.com
          </p>
          <p className="sm:sm:text-[24px] text-[20px] font-[600] text-[#102954] leading-[2px] mb-[40px]">
            7. Security{" "}
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            SpottR takes reasonable measures to help protect all information
            about you from loss, theft, misuse and unauthorized access,
            disclosure, alteration and destruction. Additionally, SpottR
            implements policies designed to protect the confidentiality and
            security of your nonpublic personal information, including a privacy
            protection policy. SpottR limits access to your nonpublic personal
            information to employees that have a business reason to know such
            information, and implement security practices and procedures
            designed to protect the confidentiality and security of such
            information and prohibit unlawful disclosure of such information in
            accordance with its policies.{" "}
          </p>
          <p className="mb-[40px] sm:text-[18px] text-[14px]">
            Where you have chosen a password that allows you to access certain
            parts of the website and mobile application, you are responsible for
            keeping this password confidential. We advise you not to share your
            password with anyone. We have also taken measures to comply with
            provision of security facilities for the protection of your
            nonpublic personal information through the setup of firewalls,
            limited access to specified authorized individuals, encryption and
            continuous capacity building for relevant personnel. We therefore
            have digital and physical security measures to limit and eliminate
            possibilities of data privacy breach incidents.
          </p>
          <p className="mb-[60px] sm:text-[18px] text-[14px]">
            Although we use appropriate security measures once we have received
            your personal information, the transmission of data over the
            internet (including by email) is never completely secure. We
            endeavour to protect personal information, but we cannot guarantee
            the security of data transmitted to us or by us. We will notify you
            and any applicable regulator of a breach where we are legally
            required to do so.
          </p>
          <p className="sm:sm:text-[24px] text-[20px] font-[600] text-[#102954] leading-[2px] mb-[40px]">
            8. Where we store your Data
          </p>
          <p className="mb-[60px] sm:text-[18px] text-[14px]">
            The data that we collect from you will be transferred to and stored
            at a destination outside Nigeria. By submitting your personal data,
            you agree to this transfer, storing or processing. We will take all
            steps reasonably necessary to ensure that your data is treated
            securely and in accordance with this privacy policy. All information
            you provide to us is stored on our secure cloud storage solution.{" "}
          </p>
        </div>
      </div>
      <div
        // ref={bottomNavRef}
        className={`w-[100%] left-0  bg-[#102954]  p-[20px]
        z-[200] right-[0px] fixed bottom-[0px] sm:flex hidden items-center justify-end
        text-[#fff] 
          `}
      >
        <button
          onClick={() => navigate("/about")}
          className="text-[20px] active:opacity-30 hover:opacity-30 font-[600]  mr-[22px]"
        >
          About
        </button>
        <button
          onClick={() => navigate("/terms")}
          className="text-[20px] active:opacity-30 hover:opacity-30 font-[600] mr-[22px]"
        >
          Terms
        </button>
        <button
          onClick={() => navigate("/privacy")}
          className="text-[20px] active:opacity-30 hover:opacity-30 font-[600] mr-[22px]"
        >
          Privacy
        </button>
        <button
          onClick={() => (window.location.href = "https://business.spottr.app")}
          className="text-[20px] active:opacity-30 hover:opacity-30 font-[600]  mr-[22px]"
        >
          Business
        </button>
        <button
          onClick={() => navigate("/contact-us")}
          className="text-[20px] active:opacity-30 hover:opacity-30 font-[600]"
        >
          Contact
        </button>
      </div>
    </div>
  );
};

export default Privacy;
